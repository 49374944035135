<template>
  <div class="tabel">
    <a-table
      :row-selection="
        status
          ? {
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }
          : null
      "
      :columns="columns"
      :data-source="data"
      class="components-table-demo-nested"
      :pagination="false"
      :loading="loading"
    >
      <!-- :scroll="{ x: 300, y: 600 }" -->
      <template slot="icu_diagnosis" slot-scope="text, record">
        <span>
          {{ record.icu_diagnosis.type }}/
          {{ record.icu_diagnosis.department }}/
          {{ record.icu_diagnosis.diagnosis_name }}
        </span>
      </template>

      <template slot="patientin_number" slot-scope="text, record">
        <span v-if="record.patient">{{ record.patient.record_number }}</span>
      </template>

      <template slot="patientin_age" slot-scope="text, record">
        <span v-if="record.patient">{{ record.patient.age }}</span>
      </template>

      <template slot="patientin_realName" slot-scope="text, record">
        <span v-if="record.user">{{ record.user.realName }}</span>
      </template>

      <template slot="num" slot-scope="text, record, index">
        <span>
          {{
          (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
          }}
        </span>
      </template>
      <template slot="platform" slot-scope="text, record">
        <img v-if="record.avatarUrl" class="portrait" :src="record.avatarUrl" />
      </template>
      <!-- 二维码 -->
      <template slot="qsCode" slot-scope="text, record">
        <img v-if="record.qsCode" class="qsCode" :src="record.qsCode" />
      </template>
      <!-- 账号   权限管理 -->
      <template v-if="operationBtn" slot="operation" slot-scope="text, record">
        <div style="display: flex; flex-wrap: wrap">
          <div v-for="(item, index) in operationBtn" :key="index" style="display: flex;">
            <a-popconfirm
              v-if="item.name=='删除'"
              title="你确认删除这条数据吗？"
              ok-text="确认"
              cancel-text="取消"
              @confirm="operation(item.type, record)"
              @cancel="cancel"
            >
              <a style="color:#F21818;">{{ item.name }}</a>
            </a-popconfirm>

            <a v-else style="color:#2468F2" @click="operation(item.type, record)">{{ item.name }}</a>
            <a-divider v-if="index !== operationBtn.length-1" type="vertical" />
          </div>
        </div>
      </template>

      <template slot="operations" slot-scope="text, record">
        <a
          v-if="
            getJurisdictionList.indexOf('patientinformation-statisticsBtn') > -1
          "
          @click="patient(text, record)"
        >查看统计数据</a>
      </template>
    </a-table>
    <!-- 分页 -->
    <div class="pagination">
      <a-pagination
        @change="handleTableChange"
        :current="pagination.current"
        :defaultPageSize="pagination.pageSize"
        :total="pagination.total"
        show-size-changer
        show-quick-jumper
        :page-size-options="pageSizeOptions"
        @showSizeChange="onShowSizeChange"
      />
    </div>
  </div>
</template>
<script>
import api from "@/api/index.js";
import storage from "../../storage/storage.js";
export default {
  props: {
    data: {},
    columns: {},
    loading: {},
    operationBtn: {
      type: Array,
    },
    status: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Object,
    },
  },
  components: {},
  data() {
    return {
      getJurisdictionList: "",
      selectedRowKeys: [],
      pageSizeOptions: ['10', '20', '30', '50', '100'],
      // pagination: {
      //   current: 1,
      //   pageSize: 10,
      //   onChange: (current, size) => {
      //     this.pagination.current = current;
      //     this.pagination.pageSize = size;
      //   },
      // },
    };
  },
  mounted() {
    this.getJurisdictionList = storage.getItem("getJurisdiction");
  },
  methods: {
    //动态操作
    operation(type, record) {
      this.$emit("operation", type, record);
    },
    cancel(e) {
      console.log(e);
    },
    patient(text) {
      api.sickinquire(text.objectId).then((res) => {
        this.$emit("sick", res);
        this.$router.push({ query: { text: text.age } });
      });
    },
    //单击分页
    handleTableChange(page, pageSize) {
      // this.selectedRowKeys = [];
      this.$emit("changePaging", page, pageSize);
    },
    //选中
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      if (this.selectedRowKeys.length > 0) {
        if (!selectedRows[0].flag) {
          api.sickinquire(this.selectedRowKeys).then((res) => {
            this.$emit("pitch", res);
          });
        } else {
          this.$emit("pitch", selectedRows);
        }
      } else {
        this.$emit("pitch", []);
      }
    },
    onShowSizeChange(page, pageSize) {
      this.$emit("changePaging", page, pageSize);
    },
  },
};
</script>
<style scoped>
.tabel {
  display: flex;
  flex-direction: column;
}
.portrait {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.qsCode {
  width: 80px;
  height: 80px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
>>> .ant-table {
  color: #666666;
}
>>> .ant-table-thead > tr > th {
  color: #787878;
}

>>> .ant-table-body {
  overflow: auto;
  max-height: calc(100vh - 210px);
}

>>> .ant-table-thead > tr th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.ant-divider,
.ant-divider-vertical {
  transform: translateY(6px);
}
</style>
